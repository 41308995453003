import React, { Component, useState, useContext, createContext, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table'
import { Field, reduxForm } from 'redux-form'
import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import RaisedButton from 'material-ui/RaisedButton'
import Paper from 'material-ui/Paper'
import DatePicker from 'material-ui/DatePicker'
import TimePicker from 'material-ui/TimePicker'
import client from '../apollo'
import {
  CreateAppointment,
  GetAppointments,
  GetUsers,
  CreatePatient,
  GetPatients
} from '@sukiai/gql/admin'
import TopNavBar from './TopNavBar'
import { getFullName } from '@sukiai/utils'
import {
  notifySuccess,
  getLastFirstName,
  setError
} from '../lib/util'
import { compose, graphql } from 'react-apollo'
import background from '../images/adminBackground.jpg'
import moment from 'moment'
import { GENDERS, FETCH_POLICY } from '../lib/constants'
import Loading from '../components/Loading'
import map from 'lodash.map'

const getOrgIDFromPath = () => window.location.pathname.split('/')[1]

// lines below and before the styles section are used to disable the refresh button
// until all three fields are selected in the search for appointments function
const DisableRefreshContext = createContext([{}, () => {}])

const initialState = {
  toDate: '',
  fromDate: '',
  email: '',
  disabled: true
}

const DisableRefreshProvider = (props) => {
  const [state, setState] = useState({ ...initialState })
  return (
    <DisableRefreshContext.Provider value={[state, setState]}>
      {props.children}
    </DisableRefreshContext.Provider>
  )
}

// This contains all of the disableRefresh functionality used by components
const useDisableRefresh = () => {
  const [, setState] = useContext(DisableRefreshContext)
  const toggleButton = () => {
    setState(prevState => ({
      ...prevState,
      disabled: !prevState.disabled
    }))
  }
  const handleRefreshButtonProps = (name, e) => {
    setState(prevState => ({
      ...prevState,
      [name]: e
    }))
  }

  return {
    toggleButton,
    handleRefreshButtonProps
  }
}

const styles = {
  root: {
    position: 'fixed',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    overflow: 'auto'
  },
  appointmentManager: {
    top: 20,
    padding: '20px',
    marginTop: '30px'
  },
  formStyle: {
    display: 'flex',
    flexFlow: 'column',
    width: '40vw',
    color: 'white',
    padding: '20px',
    marginRight: '10px'
  },
  rowFormStyle: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'flex-end'
  },
  text: {
    color: 'white'
  },
  formsContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between'
  },
  btn: {
    margin: '12px'
  },
  showing: {
    color: 'white',
    background: 'transparent',
    fontSize: '14px',
    marginBottom: '4px'
  }
}

// Viewing appointments for a selected user
const AppointmentsTable = ({ appointments }) => {
  return (
    <div>
      <Table
        height='500px'
        fixedHeader
      >
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn tooltip='Patient'>Patient Name</TableHeaderColumn>
            <TableHeaderColumn tooltip='Type'>Type</TableHeaderColumn>
            <TableHeaderColumn tooltip='Starting time'>Starts At</TableHeaderColumn>
            <TableHeaderColumn tooltip='Ending time'>Ends At</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {appointments && appointments.map((a, index) => (
            <TableRow key={index}>
              <TableRowColumn>{a.patient && a.patient.person && getFullName(a.patient.person)}</TableRowColumn>
              <TableRowColumn>{a.type}</TableRowColumn>
              <TableRowColumn>{moment(a.startsAt).format('llll')}</TableRowColumn>
              <TableRowColumn>{moment(a.endsAt).format('llll')}</TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

const mapAppointmentsToProps = ({ data }) => ({
  errorLoading: data.error,
  loadingAppointments: data.loading,
  appointments: data.appointments ? data.appointments.results : []
})

const AppointmentsList = compose(
  graphql(GetAppointments, {
    skip: props => !props.currentUser || !props.currentUser.id || !getOrgIDFromPath(),
    options: props => ({
      variables: {
        organizationId: getOrgIDFromPath(),
        ownerId: props.currentUser.id,
        startsAtRangeBeginning: moment(props.fromDate ? props.fromDate : moment()).startOf('day').toISOString(), // '2017-06-01T00:00:00-07:00', // Jun 1, 2017
        startsAtRangeEnd: moment(props.toDate ? props.toDate : moment()).endOf('day').toISOString() // Jan 1, 2117
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    }),
    props: mapAppointmentsToProps
  })
)(AppointmentsTable)

// Validate the Appointment Creation Form
const validate = values => {
  const errors = {}
  const requiredFields = [
    'ownerId',
    'patientId',
    'startTime',
    'endTime'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) =>
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => {
  const { handleRefreshButtonProps } = useDisableRefresh()
  return (
    <SelectField
      floatingLabelText={label}
      errorText={touched && error}
      {...input}
      onChange={(event, index, value) => {
        input.onChange(value)
        handleRefreshButtonProps('email', value.email)
      }}
      children={children}
      {...custom}
    />
  )
}

const renderTimePicker = ({
  input: { onBlur, ...inputProps },
  defaultTime,
  onChange,
  ...props
}) =>
  <TimePicker
    {...inputProps}
    defaultTime={defaultTime}
    onChange={(event, value) => inputProps.onChange(value)}
    {...props}
  />

const renderDatePicker = ({
  input: { onBlur, ...inputProps },
  onChange,
  ...props
}) => {
  const { handleRefreshButtonProps } = useDisableRefresh()
  return (
    <DatePicker
      {...inputProps}
      onChange={(event, value) => {
        inputProps.onChange(value)
        handleRefreshButtonProps(inputProps.name, value)
      }}
      {...props}
    />
  )
}

// Created a new component to augment "RaisedButton" which was imported from
// Material-UI
const RenderButton = () => {
  const [state] = useContext(DisableRefreshContext)
  const { toggleButton } = useDisableRefresh()
  useEffect(() => {
    if (state.fromDate && state.email && state.toDate) {
      toggleButton()
    }
  }, [state.fromDate, state.email, state.toDate])
  return (
    <RaisedButton
      type='submit'
      label='Refresh'
      disabled={state.disabled}
      primary
      style={styles.btn}
    />
  )
}

const PatientForm = props => {
  const { handleSubmit, pristine, reset, submitting } = props
  return (
    <form style={styles.formStyle} onSubmit={handleSubmit}>
      <div>
        <div>
          <Field
            name='patientPrefix'
            component={renderTextField}
            label='Prefix'
          />
        </div>
        <Field
          name='patientFirstName'
          component={renderTextField}
          label='First Name'
        />
      </div>
      <div>
        <Field
          name='patientMiddleName'
          component={renderTextField}
          label='Middle Name'
        />
      </div>
      <div>
        <Field
          name='patientLastName'
          component={renderTextField}
          label='Last Name'
        />
      </div>
      <div>
        <Field
          name='patientSuffix'
          component={renderTextField}
          label='Suffix'
        />
      </div>
      <div>
        <Field
          name='patientGender'
          component={renderSelectField}
          label='Gender'
        >
          {
            map(GENDERS, (g, index) => (
              <MenuItem
                key={index}
                value={g}
                primaryText={g}
              />
            ))
          }
        </Field>
      </div>
      <div>
        <Field
          name='patientDOB'
          component={renderDatePicker}
          hintText='Date of Birth'
        />
      </div>
      <div>
        <Field
          name='patientMRN'
          component={renderTextField}
          label='MRN (Medical Record Number)'
        />
      </div>
      <div>
        <RaisedButton
          id='patientSubmit'
          type='submit'
          label='Submit'
          disabled={pristine || submitting}
          primary
          style={styles.btn}
        />
        <RaisedButton
          type='button'
          label='Clear Values'
          disabled={pristine || submitting}
          onClick={reset}
          style={styles.btn}
        />
      </div>
    </form>
  )
}

// TODO: the value for the `MenuItem` under ownerId should actually be `doc.id`
const Form = props => {
  const { handleSubmit, pristine, reset, submitting } = props
  return (
    <form style={styles.formStyle} onSubmit={handleSubmit}>
      <div>
        <Field
          name='ownerId'
          component={renderSelectField}
          label='User'
        >
          {
            props.users && props.users.map(d => (
              <MenuItem
                key={d.id}
                value={d.id}
                primaryText={getLastFirstName(d.person)}
              />
            ))
          }
        </Field>
      </div>
      <div>
        <Field
          name='patientId'
          component={renderSelectField}
          label='Patient'
        >
          {
            props.patients && props.patients.map(p => (
              <MenuItem key={p.id} value={p.id} primaryText={getLastFirstName(p.person)} />
            ))
          }
        </Field>
      </div>
      <div>
        <Field
          name='date'
          component={renderDatePicker}
          hintText='Date'
        />
      </div>
      <div>
        <Field
          name='startsAt'
          component={renderTimePicker}
          hintText='Start Time'
        />
      </div>
      <div>
        <Field
          name='endsAt'
          component={renderTimePicker}
          hintText='End Time'
        />
      </div>
      <div>
        <Field
          name='type'
          component={renderSelectField}
          label='Type'
        >
          <MenuItem value='DEFAULT' primaryText='Default' />
          <MenuItem value='NEW' primaryText='New Patient' />
          <MenuItem value='FOLLOW_UP' primaryText='Follow up' />
          <MenuItem value='PRE_OP' primaryText='Pre-Operative' />
          <MenuItem value='POST_OP' primaryText='Post-Operative' />
        </Field>
      </div>
      <div>
        <Field
          name='reason'
          component={renderTextField}
          label='Reason'
          multiLine
          rows={2}
        />
      </div>
      <div>
        <RaisedButton
          id='apptSubmit'
          type='submit'
          label='Submit'
          disabled={pristine || submitting}
          primary
          style={styles.btn}
        />
        <RaisedButton
          type='button'
          label='Clear Values'
          disabled={pristine || submitting}
          onClick={reset}
          style={styles.btn}
        />
      </div>
    </form>
  )
}

const UserForm = props => {
  const { handleSubmit } = props

  return (
    <form style={styles.rowFormStyle} onSubmit={handleSubmit}>
      <DisableRefreshProvider>
        <div style={{ color: 'white' }}>
          <Field
            labelStyle={styles.text}
            name='user'
            component={renderSelectField}
            label='User'
          >
            {props.users.map(d =>
              <MenuItem key={d.id} value={d} primaryText={getLastFirstName(d.person)} />
            )}
          </Field>
        </div>
        <div>
          <Field
            name='fromDate'
            component={renderDatePicker}
            hintText='Start Date'
          />
        </div>
        <div>
          <Field
            name='toDate'
            component={renderDatePicker}
            hintText='End Date'
          />
        </div>
        <div>
          <RenderButton />
        </div>
      </DisableRefreshProvider>
    </form>
  )
}
// Actual Appointments Form
const AppointmentsForm = reduxForm({ form: 'AppointmentsForm', validate })(Form)
const CreatePatientForm = reduxForm({ form: 'CreatePatientForm' })(PatientForm)
// TODO (arunan): initialize with user
const UserSelectorForm = reduxForm({ form: 'UserForm' })(UserForm)

// Container to hold both the form and the table
class AppointmentManager extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currentUser: {},
      fromDate: '',
      toDate: ''
    }
  }

  componentDidMount () {
    this.props.refetchUsers({
      organizationId: getOrgIDFromPath()
    })
    this.props.refetchPatients({
      organizationId: getOrgIDFromPath()
    })
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    // Only apply on first load, ignore once already defaulted
    const isCurrentUserStateEmpty = !Object.keys(prevState.currentUser).length
    if (nextProps.users && nextProps.users.length > 0 && isCurrentUserStateEmpty) {
      return {
        currentUser: nextProps.users[0]
      }
    }
    return null
  }

  renderCreatePatientForm = () => {
    return (
      <CreatePatientForm
        onSubmit={values => {
          const dobFromForm = values.patientDOB
          const dob = moment(dobFromForm).toISOString()
          client.mutate({
            mutation: CreatePatient,
            variables: {
              organizationId: getOrgIDFromPath(),
              dateOfBirth: dobFromForm ? dob : null,
              gender: values.patientGender,
              firstName: values.patientFirstName,
              middleName: values.patientMiddleName,
              lastName: values.patientLastName,
              prefix: values.patientPrefix,
              suffix: values.patientSuffix,
              mrn: values.patientMRN
            },
            refetchQueries: [{
              query: GetPatients,
              variables: {
                organizationId: getOrgIDFromPath(),
                limit: 50,
                offset: 0
              }
            }]
          }).then(({ data }) => {
            console.info('Created Patient.')
            notifySuccess('Successfully created patient.')
          }).catch(error => {
            console.error('Error creating patient', error)
            setError(error)
          })
        }}
      />
    )
  }

  renderAppointmentsForm = () => {
    return (
      <AppointmentsForm
        users={this.props.users}
        patients={this.props.patients}
        onSubmit={(values) => {
          const dateParsed = moment(values.date).utc().format('YYYY-MM-DD')
          const startTimeParsed = moment(values.startsAt).format('HH:mm:ssZ')
          const startTime = `${dateParsed}T${startTimeParsed}`
          const endTimeParsed = moment(values.endsAt).format('HH:mm:ssZ')
          const endTime = `${dateParsed}T${endTimeParsed}`

          // by default fetch appointments of yesteray, today and tomorrow
          const fromDate = moment().subtract(1, 'days').startOf('day').toISOString()
          const toDate = moment().add(1, 'days').startOf('day').toISOString()

          client.mutate({
            mutation: CreateAppointment,
            variables: {
              organizationId: getOrgIDFromPath(),
              ownerId: values.ownerId, // TODO: actually populate using relevant users
              patientId: values.patientId, // TODO: actually use patient ID rather than name
              startsAt: startTime,
              endsAt: endTime,
              type: values.type,
              reason: values.reason
            },
            refetchQueries: [{
              query: GetAppointments,
              variables: {
                organizationId: getOrgIDFromPath(),
                ownerId: this.state.currentUser.id,
                startsAtRangeBeginning: fromDate,
                startsAtRangeEnd: toDate
              }
            }]
          }).then(({ data }) => {
            console.info('Created Appointment.')
            notifySuccess('Successfully created appointment.')
          }).catch(error => {
            console.error('Error creating appointment', error)
            setError(error)
          })
        }}
      />
    )
  }

  render () {
    const {
      patients,
      users,
      loadingPatients,
      loadingUsers
    } = this.props

    // if (patients === null || loadingPatients || users === null || loadingUsers) {
    //   return <Loading />
    // }
    // (patients !== null && !loadingPatients && users !== null && !loadingUsers)

    return (
      <div style={styles.root}>
        <TopNavBar />
        {(patients !== null && !loadingPatients && users !== null && !loadingUsers)
          ? (
            <div style={styles.appointmentManager}>
              <div>
                <div style={styles.formsContainer}>
                  <Paper zDepth={2} rounded={false}>
                    {this.renderAppointmentsForm()}
                  </Paper>
                  <Paper zDepth={2} rounded={false}>
                    {this.renderCreatePatientForm()}
                  </Paper>
                </div>
                <br />
                <div style={styles.showing}>
                  <UserSelectorForm users={this.props.users} onSubmit={(values) => { this.setState({ currentUser: values.user, fromDate: values.fromDate, toDate: values.toDate }) }} />
                </div>
                <AppointmentsList users={this.props.users} currentUser={this.state.currentUser} fromDate={this.state.fromDate} toDate={this.state.toDate} />
              </div>
            </div>
          ) : <Loading />}
      </div>
    )
  }
}

// Load users into props
const mapUsersToProps = ({ ownProps, data }) => ({
  refetchUsers: data.refetch,
  errorUsers: data.error,
  loadingUsers: data.loading,
  users: data.users ? data.users.results : []
})

// Load patients into props
const mapPatientsToProps = ({ ownProps, data }) => ({
  refetchPatients: data.refetch,
  errorPatient: data.error,
  loadingPatients: data.loading,
  patients: data.patients ? data.patients.results : []
})

export default compose(
  graphql(GetUsers, {
    skip: () => !getOrgIDFromPath(),
    options: () => ({
      variables: {
        organizationId: getOrgIDFromPath()
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    }),
    props: mapUsersToProps
  }),
  graphql(GetPatients, {
    skip: () => !getOrgIDFromPath(),
    options: () => ({
      variables: {
        organizationId: getOrgIDFromPath(),
        limit: 50,
        offset: 0
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    }),
    props: mapPatientsToProps
  }),
  reduxForm({
    form: 'AppointmentManager' // a unique identifier for this form
  })
)(AppointmentManager)
